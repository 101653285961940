import { useConnectionStore } from '@compenda/vue/plugin-apollo';
import { createMemoryHistory, createRouter, RouteRecordRaw } from 'vue-router';

const HomePage = async () => await import('./pages/HomePage.vue');
const LoggedInLayout = async () => await import('./layouts/LoggedIn.vue');
const LoginPage = async () =>
	(await import('@compenda/vue/feature-login')).LoginPage;
const LogoutPage = async () =>
	(await import('@compenda/vue/feature-login')).LogoutPage;
const ArchivePage = async () =>
	(await import('@compenda/vue/feature-archive-email')).ArchivePage;
const SendArchivePage = async () =>
	(await import('@compenda/vue/feature-archive-email')).SendArchivePage;
const SettingsPage = async () =>
	(await import('@compenda/vue/feature-outlook-settings')).SettingsPage;

const routes: RouteRecordRaw[] = [
	{ name: 'Home', path: '/', component: HomePage },
	{
		path: '/archive-email',
		component: LoggedInLayout,
		children: [{ name: 'ArchiveEmail', path: '', component: ArchivePage }],
	},
	{
		path: '/archive-send-email',
		component: LoggedInLayout,
		children: [
			{ name: 'ArchiveSendEmail', path: '', component: SendArchivePage },
		],
	},
	{ name: 'Settings', path: '/settings', component: SettingsPage },
	{
		name: 'Login',
		path: '/login',
		component: LoginPage,
		props: { customEndpoint: true },
	},
	{ name: 'Logout', path: '/logout', component: LogoutPage },
];

const router = createRouter({
	history: createMemoryHistory(),
	routes,
});

router.beforeEach(async (to) => {
	const connectionStore = useConnectionStore();
	if (
		!connectionStore.isAuthenticated() &&
		// Avoid an infinite redirect
		to.name !== 'Login' &&
		to.name !== 'Home'
	) {
		// refresh the token first
		await connectionStore.refresh();
		if (!connectionStore.isAuthenticated()) return { name: 'Login' };
	}
});

export default router;
